<template>
	<div class="teams-container" id="team">
		<div class="teams">
			<div class="title">{{ team_in.title }}</div>
			<div class="team-content">
				<div
					v-for="(item, index) in team_in.team"
					:key="index"
					class="team-item"
				>
					<img
						:src="item.profile_pic"
						:alt="item.name"
						srcset=""
						class="profile-pic"
					/>
					<span class="name">{{ item.name }}</span>
					<span class="designation">{{ item.designation }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			team_in: {
				title: "Team",
				team: [
					{
						name: "Gaurav Gaglani",
						designation: "CEO/Director - Magassians VOIP Pvt Ltd",
						profile_pic: require("../../assets/gaurav.jpeg"),
					},
					{
						name: "Ahmed Sakri",
						designation: "Sr. Software Engineer",
						profile_pic: require("../../assets/ahmed.jpeg"),
					},
					{
						name: "Bharti Sharma",
						designation: "Sr. Software Engineer",
						profile_pic: require("../../assets/bharti.jpeg"),
					},
					{
						name: "Aniket Joshi",
						designation: "Sr. Software Engineer",
						profile_pic: require("../../assets/aniket.jpeg"),
					},
					{
						name: "Kamalesh Mohapatra",
						designation: "Engineer",
						profile_pic: require("../../assets/kamalesh.jpeg"),
					}
				],
			},
		};
	},
};
</script>
<style lang="less" scoped>
.teams-container {
	padding: 20px;
	transition: 1s ease;
	max-width: 1280px;
	margin: 0 auto;
	.teams {
		padding: 20px;
		max-width: 1280px;
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		.title {
			font-weight: bold;
			text-align: center;
			font-size: 28px;
			padding-bottom: 20px;
		}
		.team-content {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			.team-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 20px;
				&:hover {
					background: #fff;
					border-radius: 5px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
					transform: scale(1.1);
				}
				.profile-pic {
					width: 100px;
					height: 100px;
					border-radius: 50%;
				}
				.name {
					font-size: 20px;
					font-weight: bold;
					margin: 10px 0 0 0;
				}
				.designation {
					font-size: 12px;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.teams-container {
		padding: 20px;
		transition: 1s ease;
		max-width: 1280px;
		margin: 0 auto;
		.teams {
			padding: 0;
			max-width: 1280px;
			margin: 0 auto;
			width: 100%;
			display: flex;
			flex-direction: column;
			.title {
				font-weight: bold;
				text-align: center;
				font-size: 28px;
				padding-bottom: 20px;
			}
			.team-content {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				.team-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 20px 0;
					text-align: center;
					&:hover {
						background: none;
						border-radius: 0;
						box-shadow: none;
					}
					.profile-pic {
						width: 100px;
						height: 100px;
						border-radius: 50%;
					}
					.name {
						font-size: 20px;
						font-weight: bold;
						margin: 10px 0 0 0;
					}
					.designation {
						font-size: 12px;
					}
				}
			}
		}
	}
}
</style>
