<template>
	<div class="header-container">
		<div class="header">
			<div class="header-logo">
				<router-link :to="'/'">
					<img :src="logo" :alt="title" />
				</router-link>
			</div>
			<div class="menus">
				<div class="menu" v-for="(item, index) in menus" :key="index">
					<a :href="item.path">
						<div class="menu-item">
							<div class="menu-item-text">
								{{ item.name }}
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			logo: require("../assets/logo.png"),
			title: "magassians",
			menus: [
				{
					name: "Case Studies",
					path: "#case-studies-overview",
				},
				{
					name: "Team",
					path: "#team",
				},
				// {
				// 	name: "Clients",
				// 	path: "#clients",
				// },
				// {
				// 	name: "Careers",
				// 	path: "#careers",
				// },
			],
		};
	},
};
</script>
<style lang="less" scoped>
.header-container {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	.header {
		padding: 10px 0;
		max-width: 1280px;
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;
		.header-logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			img {
				width: 100%;
			}
		}
		.menus {
			display: flex;
			justify-content: center;
			align-items: center;
			.menu {
				margin-left: 15px;
				.menu-item {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100px;
					height: 50px;
					border-radius: 5px;
					.menu-item-text {
						font-size: 14px;
						font-weight: 700;
						color: #005dbb;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.header-container {
		.header {
			padding: 10px;
		}
	}
}
</style>
