<template>
	<Transition name="modal">
		<div v-if="show" class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header">
						<div class="modal-title">{{ title }}</div>
						<div class="cross" @click="$emit('close')">
							<img src="../../assets/close.svg" alt="Close" />
						</div>
					</div>
					<div class="modal-body">
						<slot />
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>
<script>
export default {
	props: {
		show: Boolean,
		title: {
			type: String,
			required: true,
		},
	},
};
</script>
<style lang="less" scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	padding: 4px 25px;
	background-color: #ffffff;
	min-width: 300px;
	border: 1px solid #ffffff;
	min-height: 100px;
	max-width: 720px;
	position: relative;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	overflow: auto;
	margin: 0px auto;
	border-radius: 4px;

	.modal-header {
		padding: 10px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.modal-body {
		margin-top: 20px;
		height: 400px;
		overflow-y: auto;
	}
	.cross {
		cursor: pointer;
		img {
			width: 17px;
		}
	}
	.modal-title {
		margin: auto;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-body {
	margin: 20px 0;
}

.modal-default-button {
	float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
