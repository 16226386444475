<template>
	<div class="footer-container">
		<div class="footer">
			<div class="footer-content">
				<div class="logo">
					<img :src="logo" alt="Logo" />
				</div>
				<div class="contact-container">
					<div class="contact-item">
						<div class="contact-item-icon">
							<img :src="mail" alt="mail" />
						</div>
						<div class="contact-item-text">
							<div class="contact-item-text-content">
								<a href="mailto:hello@magassians.co"
									>hello@magassians.co</a
								>
							</div>
						</div>
					</div>
					<div class="contact-item">
						<div class="contact-item-icon">
							<img :src="location" alt="mail" />
						</div>
						<div class="contact-item-text">
							<div class="contact-item-text-content">
								<a
									href="https://goo.gl/maps/fcBnSkZM5YcS8Vhd8"
									target="_blank"
								>
									<div>{{ address.address1 }}</div>
									<div>{{ address.address2 }}</div>
									<div>
										{{ address.city }}, {{ address.state }},
										{{ address.country }} -
										{{ address.zip }}
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright-text">
			Copyright &copy; {{ getYear }} Magassains VOIP Private Limited. All
			rights reserved.
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			logo: require("../assets/logo.png"),
			mail: require("../assets/mail.svg"),
			location: require("../assets/location.svg"),
			address: {
				address1: "Magassians Technologies Pvt. Ltd. ",
				address2: "D-315, Neelkanth Business Park, Vidyavihar West",
				city: "Mumbai",
				state: "Maharashtra",
				country: "India",
				zip: "400086",
			},
		};
	},
	computed: {
		getYear() {
			return new Date().getFullYear();
		},
	},
};
</script>
<style lang="less" scoped>
.footer-container {
	background-color: #fff;
	padding: 20px 0;
	width: 100%;
	.footer {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.footer-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding-bottom: 15px;
			.logo {
				width: 100px;
				img {
					width: 100%;
				}
			}
			.contact-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.contact-item {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					padding: 10px 0;
					.contact-item-icon {
						width: 20px;
						margin-right: 20px;
						img {
							width: 100%;
						}
					}
					a {
						color: #2c3e50;
						&:hover {
							color: #005dbb;
						}
					}
					.contact-item-text {
						display: flex;
						flex-direction: column;
						justify-content: center;
						min-width: 300px;
						max-width: 300px;
						.contact-item-text-content {
							font-size: 12px;
							color: #2c3e50;
						}
					}
				}
			}
		}
	}
	.copyright-text {
		padding-top: 20px;
		font-size: 12px;
		color: #000;
		border-top: 1px solid #e6e6e6;
		text-align: center;
	}
}
@media screen and (max-width: 768px) {
	.footer-container {
		.footer {
			.footer-content {
				flex-direction: column;
				.logo {
					width: 80px;
					img {
						width: 100%;
					}
				}
				.contact-container {
					.contact-item {
						.contact-item-icon {
							width: 15px;
							margin-right: 15px;
							img {
								width: 100%;
							}
						}
						.contact-item-text {
							min-width: initial;
							max-width: initial;
						}
					}
				}
			}
		}
	}
}
</style>
