<template>
  <div class="homepage">
    <Info />
    <CaseStudiesOverView />
    <Teams />
    <!-- <Clients /> -->
    <!-- <Careers /> -->
  </div>
</template>

<script>
import Info from "../global/components/Info.vue";
import Teams from "../global/components/Teams.vue";
// import Clients from "../global/components/Clients.vue";
// import Careers from "../global/components/Careers.vue";
import CaseStudiesOverView from "../global/components/CaseStudiesOverview.vue";

export default {
  name: "Homepage",
  components: {
    Info,
    CaseStudiesOverView,
    Teams,
    // Clients,
    // Careers,
  },
};
</script>

<style lang="less" scoped>
.homepage {
  width: 100%;
  margin: 0 auto;
}
</style>
