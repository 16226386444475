<template>
	<div class="info-bg">
		<div class="info-container">
			<div class="title">
				{{ title }}
			</div>
			<div class="subtitle">{{ subtitle }}</div>
			<div class="technology-container">
				<ul
					class="tech-item"
					v-for="(item, index) in tech"
					:key="index"
				>
					<li>
						<img :src="item.logo" :alt="item.name" />
						<span>{{ item.name }}</span>
					</li>
				</ul>
			</div>
		</div>
		<!-- <a href="#careers" class="hiring">
			<img src="../../assets/hiring.svg" alt="" />
		</a> -->
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: "We are Magassians, a team of software engineers solving real-world problems in the Telecom and VOIP space.",
			subtitle:
				"We specialize in building highly scalable, distributed systems. We design, develop, deploy, and orchestrate our applications and services ourselves.",
			tech: [
				{
					name: "Go",
					logo: require("../../assets/tech/go.svg"),
				},
				{
					name: "Vue.js",
					logo: require("../../assets/tech/vuejs.svg"),
				},
				{
					name: "Cockroach DB",
					logo: require("../../assets/tech/cockroachdb.svg"),
				},
				{
					name: "Docker",
					logo: require("../../assets/tech/docker.svg"),
				},
				{
					name: "Kubernetes",
					logo: require("../../assets/tech/kubernets.svg"),
				},
				{
					name: "PostgreSQL",
					logo: require("../../assets/tech/postgresql.svg"),
				},
				{
					name: "Grafana",
					logo: require("../../assets/tech/grafana.svg"),
				},
				{
					name: "Prometheus",
					logo: require("../../assets/tech/prometheus.svg"),
				},
			],
		};
	},
};
</script>
<style lang="less" scoped>
.info-bg {
	background: url(../../assets/voip.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right 0 top;
	position: relative;
	.info-container {
		padding: 20px 0;
		border-radius: 5px;
		margin: 2px auto 0 auto;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		max-width: 1320px;
		height: 600px;
		.title {
			font-size: 28px;
			font-weight: bold;
			padding: 20px;
			margin: 20px 0 0 0;
			width: 50%;
			text-align: left;
		}
		.subtitle {
			font-size: 16px;
			width: 600px;
			padding: 0 20px 20px 20px;
			line-height: 22px;
		}
		.technology-container {
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			padding: 20px;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 1s ease;
			cursor: pointer;
			position: absolute;
			bottom: 0;
			&:hover {
				background: #fff;
			}
			.tech-item {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 30px;
				padding: 0;
				list-style: none;
				li {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					margin: 10px;
					img {
						width: 50px;
					}
					span {
						margin-top: 5px;
					}
				}
			}
		}
	}
	.hiring {
		position: absolute;
		top: 0;
		right: 20px;
		width: 150px;
		img {
			-moz-animation: blink normal 1s infinite ease-in-out; /* Firefox */
			-webkit-animation: blink normal 1s infinite ease-in-out; /* Webkit */
			-ms-animation: blink normal 1s infinite ease-in-out; /* IE */
			animation: blink normal 1s infinite ease-in-out; /* Opera and prob css3 final iteration */
		}
	}
}

@-moz-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
/* IE */
@-ms-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
/* Opera and prob css3 final iteration */
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (max-width: 768px) {
	.info-bg {
		.info-container {
			.title {
				width: 100%;
				margin: 180px 0 0 0;
			}
			.subtitle {
				width: 100%;
			}
			.technology-container {
				overflow-x: auto;
				background: #fff;
				padding: 10px;
				white-space: nowrap;
				width: 100%;
				justify-content: inherit;
				.tech-item {
					margin: 0 30px;
					li {
						margin: 10px 0;
						img {
							width: 35px;
						}
						span {
							font-size: 12px;
						}
					}
				}
			}
		}
		.hiring {
			width: 80px;
		}
	}
}
::-webkit-scrollbar {
	display: none;
}
</style>
