<template>
	<div class="app">
		<Header></Header>
		<router-view></router-view>
		<Footer></Footer>
	</div>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
	components: {
		Header,
		Footer,
	},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
* {
	scroll-behavior: smooth;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
#app {
	background: #f3f7fe;
}
a {
	text-decoration: none;
}
img {
	object-fit: contain;
	width: 100%;
}
</style>
